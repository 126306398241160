import React, { useRef, useEffect, useState } from "react";
import EmailEditor from "react-email-editor";
import {
  createEmailTemplate,
  previewEmail,
  updateEmailTemplate,
} from "../../services/messageCenter";
import { fonts } from "./fonts";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../store/loadingIndicator/actions";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Redirect, NavLink, useHistory, useLocation } from "react-router-dom";
import ClipboardIconPopup from "../../assets/images/clipboard-icon-popup.svg";
export const CustomEmailEditor = () => {
  const emailEditorRef: any = useRef(null);
  const [title, setTitle] = useState("");
  const [emails, setEmails] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const userData = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  let appearanceConfig: any = {
    theme: "light",
  };
  let optionsConfig: any = {
    fonts: {
      showDefaultFonts: true,
      customFonts: fonts,
    },
  };

  useEffect(() => {
    console.log("locationSet", location);
    if (location && location.state && location.state.templatename) {
      setTitle(location.state.templatename);
    }
  }, []);
  const previewTemplate = (templateId: any) => {
    previewEmail({
      emails: emails,
      templateid: templateId,
      clinicid: userData.clinics[0].id,
    })
      .then((success) => {
        toast.success("Preview sent successfully.");
      })
      .catch((error) => {
        toast.success("Preview sent failed.");
      });
  };
  const exportHtml = (preview = false) => {
    if (!title) {
      toast.error("Title required.");
      return;
    }
    emailEditorRef.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      if (location && location.state && location.state.templatejson) {
        const request = {
          clinicid: userData.clinics[0].id,
          emailtemplate_id: location.state.messagecenteremailtemplate_id,
          templatename: title,
          templatehtml: html,
          templatejson: JSON.stringify(design),
          isactive: location.state.isactive,
        };
        dispatch(fetchLoadingPending());
        updateEmailTemplate(request)
          .then((success) => {
            dispatch(fetchLoadingSuccess(false));
            console.log("successEmail ", success);
            if (preview) {
              previewTemplate(location.state.messagecenteremailtemplate_id);
            } else {
              history.push({ pathname: "/message-center/templates" });
            }
          })
          .catch((error) => {
            dispatch(fetchLoadingSuccess(false));
            console.log("Error while create email template ", error);
          });
      } else {
        const request = {
          clinicid: userData.clinics[0].id,
          templatename: title,
          templatehtml: html,
          templatejson: JSON.stringify(design),
        };
        dispatch(fetchLoadingPending());
        createEmailTemplate(request)
          .then((success) => {
            dispatch(fetchLoadingSuccess(false));
            console.log("successEmail", success);
            if (preview) {
              previewTemplate(success.data.messagecenteremailtemplate_id);
            } else {
              history.push({ pathname: "/message-center/templates" });
            }
          })
          .catch((error) => {
            dispatch(fetchLoadingSuccess(false));
            console.log("Error while create email template", error);
          });
      }
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    if (location && location.state && location.state.templatejson) {
      const templateJson = JSON.parse(location.state.templatejson);
      console.log("templateJson", templateJson);

      emailEditorRef.current.editor.loadDesign(templateJson);
    }
    console.log("onReady");
  };
  const handleSaveAndPreview = () => {
    if (emails !== "") {
      exportHtml(true);
    } else {
      toast.error("Email in comma seperated email is required");
    }
  };
  return (
    <div>
      <div className="short-codes-box m-3">
        <div className="mb-3">
          <span className="fw-bold">Short codes </span>
          <small className="text-muted">(click icon to copy code.)</small>
        </div>
        <div className="d-flex short-code-btns flex-wrap">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip">
                {"Use {patientsigninemail} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{patientsigninemail}"}
              onCopy={() => toast.success("Patient Signin Email is copied!")}
            >
              <button className="btn p-0" type="button">
                PatientSigninEmail
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip">
                {"Use {patientsigninmobile} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{patientsigninmobile}"}
              onCopy={() => toast.success("Patient Signin Mobile is copied!")}
            >
              <button className="btn p-0" type="button">
                PatientSigninMobile
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip">
                {"Use {patientsigninpassword} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{patientsigninpassword}"}
              onCopy={() => toast.success("Patient Signin Password is copied!")}
            >
              <button className="btn p-0" type="button">
                PatientSigninPassword
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {
                  "Use {multipleappointmentnote} in body to attach multiple appointment note."
                }
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{multipleappointmentnote}"}
              onCopy={() => toast.success("Multiple appointment note copied!")}
            >
              <button className="btn p-0" type="button">
                Multiple appointment note
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {appointmentday} in body to attach appointment day."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{appointmentday}"}
              onCopy={() => toast.success("appointment date copied!")}
            >
              <button className="btn p-0" type="button">
                Appointment day
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {appointmentdate} in body to attach appointment date."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{appointmentdate}"}
              onCopy={() => toast.success("appointment date copied!")}
            >
              <button className="btn p-0" type="button">
                Appointment date
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {
                  "Use {appointmentstarttime} in body to attach appointment start time."
                }
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{appointmentstarttime}"}
              onCopy={() => toast.success("appointment start time copied!")}
            >
              <button className="btn p-0" type="button">
                Appointment start time
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {
                  "Use {appointmentstatus} in body to attach appointment status."
                }
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{appointmentstatus}"}
              onCopy={() => toast.success("appointment status copied!")}
            >
              <button className="btn p-0" type="button">
                Appointment status
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {patientname} in body to attach patient name."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{patientname}"}
              onCopy={() => toast.success("patientname copied!")}
            >
              <button className="btn p-0" type="button">
                Patient name
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {providername} in body to attach provider name."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{providername}"}
              onCopy={() => toast.success("provider name copied!")}
            >
              <button className="btn p-0" type="button">
                Provider name
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {amount} in body to attach provider name."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{amount}"}
              onCopy={() => toast.success("Amount copied!")}
            >
              <button className="btn p-0" type="button">
                Amount
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {clinicname} in body to attach provider name."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{clinicname}"}
              onCopy={() => toast.success("Clinic name copied!")}
            >
              <button className="btn p-0" type="button">
                Clinic name
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {speciality} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{speciality}"}
              onCopy={() => toast.success("Speciality is copied!")}
            >
              <button className="btn p-0" type="button">
                Speciality
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {clinicsmsnumber} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{clinicsmsnumber}"}
              onCopy={() => toast.success("ClinicSMSNumber is copied!")}
            >
              <button className="btn p-0" type="button">
                ClinicSMSNumber
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {clinicadminemail} in body to attach speciality."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{clinicadminemail}"}
              onCopy={() => toast.success("ClinicAdminEmail is copied!")}
            >
              <button className="btn p-0" type="button">
                ClinicAdminEmail
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {prescreeningdate} in body to attach prescreening date."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{prescreeningdate}"}
              onCopy={() => toast.success("PrescreeningDate is copied!")}
            >
              <button className="btn p-0" type="button">
                Prescreening date
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {prescreeningtime} in body to attach prescreening time."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{prescreeningtime}"}
              onCopy={() => toast.success("PrescreeningTime is copied!")}
            >
              <button className="btn p-0" type="button">
                Prescreening time
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {
                  "Use {prescreeningorigin} in body to attach prescreening time."
                }
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{prescreeningorigin}"}
              onCopy={() => toast.success("PrescreeningOrigin is copied!")}
            >
              <button className="btn p-0" type="button">
                Prescreening origin
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {
                  "Use {prescreeningstatus} in body to attach prescreening time."
                }
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{prescreeningstatus}"}
              onCopy={() => toast.success("PrescreeningStatus is copied!")}
            >
              <button className="btn p-0" type="button">
                Prescreening status
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {confirmlink} in body to attach confirm link."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{confirmlink}"}
              onCopy={() => toast.success("Confirm link copied!")}
            >
              <button className="btn p-0" type="button">
                Confirm Link
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {reschedulelink} in body to attach reschedule link."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{reschedulelink}"}
              onCopy={() => toast.success("Reschedule link copied!")}
            >
              <button className="btn p-0" type="button">
                Reschedule Link
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {prescreeninglink} in body to attach prescreening link."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{prescreeninglink}"}
              onCopy={() => toast.success("Prescreening link copied!")}
            >
              <button className="btn p-0" type="button">
                Prescreening Link
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {reviewlink} in body to attach review link."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{unsubscribelink}"}
              onCopy={() => toast.success("UnSubscribe link copied!")}
            >
              <button className="btn p-0" type="button">
                Unsubscribe Link
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id="tooltip">
                {"Use {reviewlink} in body to attach review link."}
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={"{referralcode}"}
              onCopy={() => toast.success("Referral code copied!")}
            >
              <button className="btn p-0" type="button">
                Referral Code
                <img src={ClipboardIconPopup} className="ms-2" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
        </div>
      </div>
      <div className="m-3 row d-flex">
        <div className=" m-3 col-3">
          <label htmlFor="campaignName" className="form-label fw-bold">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="campaignName"
            value={title}
            name="emailtemplate"
            placeholder="Email title"
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
          />
          {/* <small className="text-muted">ex. recall reminder.</small> */}
          {/* {
            (errors && errors.campaignName && errors.campaignName.type === "required") &&
            < div className="is-invalid">Campaign Name is required</div>
          } */}
        </div>
        <div className=" m-3 col-8 row">
          <div className="col-9">
            <label htmlFor="campaignName" className="form-label fw-bold">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              id="campaignName"
              value={emails}
              name="emailtemplate"
              placeholder="Enter single or comma separated email id’s"
              onChange={(e: any) => {
                setEmails(e.target.value);
              }}
            />
          </div>
          <div className="col d-flex align-items-end">
            <button className="btn btn-primary" onClick={handleSaveAndPreview}>
              Save & Preview
            </button>
          </div>
        </div>
      </div>
      <EmailEditor
        options={optionsConfig}
        appearance={appearanceConfig}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary"
          onClick={() => {
            exportHtml();
          }}
        >
          {location && location.state && location.state.templatejson
            ? "Update Template"
            : "Create Template"}
        </button>
      </div>
    </div>
  );
};
